import { io } from "socket.io-client";
import appConfig from "configs/app.config";

let socket;

const registerSocket = (userId) => {
  if (socket) return socket;

  socket = io(`${appConfig.apiBaseUrl}`, {
    reconnectionDelayMax: 10000,
    query: {
      userId: userId,
    },
    transports: ["websocket"], // Force WebSocket transport
    reconnection: true, // Enable reconnection
    reconnectionAttempts: 5, // Try to reconnect 5 times
    reconnectionDelay: 1000, // Wait 1 second before retrying
  });

  // Connection event handlers
  socket.on("connect", () => {
    console.log("Socket connected with ID:", socket.id);
  });

  socket.on("connect_error", (error) => {
    console.error("Socket connection error:", error);
  });

  socket.on("disconnect", (reason) => {
    console.log("Socket disconnected:", reason);
  });

  // Handle socket connection confirmation
  socket.on("socketConnected", (data) => {
    console.log("Socket connection confirmed:", data);
  });

  // Handle progress updates
  socket.on("bulkDownloadProgress", (data) => {
    console.log("Progress update:", data);
    // You can add your progress handling logic here
    // For example, updating a progress bar or showing status messages
  });

  // Handle zip file downloads
  socket.on("zip", (data) => {
    console.log("Received zip data:", data);
    if (!Array.isArray(data) || data.length === 0) {
      console.error("Invalid zip data received");
      return;
    }

    const fileUrls = data.map((download) => ({
      link: download?.url,
      name: download?.name,
    }));

    // Start download after a short delay
    setTimeout(async () => {
      for (const url of fileUrls) {
        try {
          const response = await fetch(url.link);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const blob = await response.blob();
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = downloadUrl;
          link.setAttribute("download", `${url.name}`);
          link.download = url.name;
          document.body.appendChild(link);
          link.click();

          // Cleanup
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(link);

          console.log(`Successfully downloaded: ${url.name}`);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      }
    }, 1000); // Reduced delay to 1 second
  });

  // Handle errors
  socket.on("socketErr", (error) => {
    console.error("Socket error:", error);
  });

  return socket;
};

const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    console.log("Socket disconnected and cleaned up");
  }
};

const getSocket = () => socket;

export { registerSocket, getSocket, disconnectSocket };
